import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  ListItemButton,
  Tooltip,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import CtaBorderButton from "../../components/button/cta-border-button.component";
import { SnackbarContext } from "../../components/notification/snackbar.context";
import Text from "../../components/text.component";
import routes from "../../navigation/routes";
import { resetUser, staffLogout } from "../../services/staff/auth/auth.slice.services";
import { getUserPermission, getUserRole, isMerchantStaff } from "../utils";
import { logout } from "../../services/auth/auth.slice.services";

const StyledText = styled(Text, {
  shouldForwardProp: (prop) => prop !== "isSmallScreen",
})(({ theme, isSmallScreen }) => ({
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
  fontSize: theme.fonts.fontSizes.size18,
  paddingLeft: isSmallScreen ? "0" : "30px",
}));

const ColumnFlexBox = styled(Box)({ display: "flex", flexDirection: "column", height: "100%" });

const SeperateLine = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  backgroundColor: theme.palette.colors.ui.border,
}));

const FlexEndBox = styled(Box)({
  display: "flex",
  height: "100%",
  alignItems: "flex-end",
  width: "100%",
  marginBottom: "40px",
});

const AccordionDetailsWithoutPadding = styled(AccordionDetails)(({ theme }) => ({
  padding: "0px",
  paddingLeft: theme.spacing(4),
}));

function DrawerItems({ openDrawer, setOpenDrawer }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("800"));
  const createSnackBar = useContext(SnackbarContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const handleDrawerToggle = () => {
    if (setOpenDrawer) {
      setOpenDrawer(!openDrawer);
    }
  };

  const handleLogout = () => {
    if (!isMerchantStaff()) {
      dispatch(logout()).then(({ meta, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({ message: payload.message, type: "success" });
          history.push(routes.LOGIN);
        }
        if (meta.requestStatus === "rejected") {
          dispatch(resetUser());
          createSnackBar({ message: "Successfully logged out", type: "success" });
          history.push(routes.LOGIN);
        }
      });
    }
    if (isMerchantStaff()) {
      dispatch(staffLogout()).then(({ meta, payload }) => {
        if (meta.requestStatus === "fulfilled") {
          createSnackBar({ message: payload.message, type: "success" });
          history.push(routes.LOGIN_STAFF);
        }
        if (meta.requestStatus === "rejected") {
          dispatch(resetUser());
          createSnackBar({ message: "Successfully logged out", type: "success" });
          history.push(routes.LOGIN_STAFF);
        }
      });
    }
  };

  return (
    <ColumnFlexBox>
      <Grid container columnSpacing={2} display="block">
        {(getUserRole().some((role) => role === "merchant-owner") ||
          getUserPermission().some(
            (permission) =>
              permission === "ms-operation-read" || permission === "ms-operation-write",
          )) && (
          <Grid item xs={12} sx={{ paddingY: "12px" }}>
            <Link
              style={{ textDecoration: "none" }}
              onClick={handleDrawerToggle}
              to={{ pathname: routes.HOME }}
            >
              <StyledText
                type={location.pathname === "/" ? "BrandColor" : null}
                isSmallScreen={isSmallScreen}
              >
                Home
              </StyledText>
            </Link>
          </Grid>
        )}
        {(getUserRole().some((role) => role === "merchant-owner") ||
          getUserPermission().some(
            (permission) => permission === "ms-finance-read" || permission === "ms-finance-write",
          )) && (
          <>
            <Grid item xs={12} sx={{ paddingY: "12px" }}>
              <Link
                style={{ textDecoration: "none" }}
                onClick={handleDrawerToggle}
                to={{ pathname: routes.REVENUE }}
              >
                <StyledText
                  type={location.pathname.toUpperCase().includes("REVENUE") ? "BrandColor" : null}
                  isSmallScreen={isSmallScreen}
                >
                  Revenue
                </StyledText>
              </Link>
            </Grid>
            <Grid item xs={12} sx={{ paddingY: "12px" }}>
              <Link
                style={{ textDecoration: "none" }}
                onClick={handleDrawerToggle}
                to={{ pathname: routes.CREDIT }}
              >
                <StyledText
                  type={location.pathname.toUpperCase().includes("CREDIT") ? "BrandColor" : null}
                  isSmallScreen={isSmallScreen}
                >
                  Credits
                </StyledText>
              </Link>
            </Grid>
          </>
        )}

        {(getUserRole().some((role) => role === "merchant-owner") ||
          getUserPermission().some(
            (permission) =>
              permission === "ms-operation-read" ||
              permission === "ms-operation-write" ||
              permission === "ms-trainer-read" ||
              permission === "ms-trainer-write",
          )) && (
          <Grid item xs={12} sx={{ paddingY: "12px" }}>
            <Tooltip title="Booking schedules" arrow placement="right">
              <Link
                style={{ textDecoration: "none" }}
                onClick={handleDrawerToggle}
                to={{ pathname: routes.CALENDAR }}
              >
                <StyledText
                  type={location.pathname.toUpperCase().includes("CALENDAR") ? "BrandColor" : null}
                  isSmallScreen={isSmallScreen}
                >
                  Calendar
                </StyledText>
              </Link>
            </Tooltip>
          </Grid>
        )}

        {(getUserRole().some((role) => role === "merchant-owner") ||
          getUserPermission().some(
            (permission) =>
              permission === "ms-operation-read" || permission === "ms-operation-write",
          )) && (
          <Grid item xs={12} sx={{ paddingY: "12px" }}>
            <Tooltip
              title="Marketing tools to attract and retain more users"
              arrow
              placement="right"
            >
              <Link
                style={{ textDecoration: "none" }}
                onClick={handleDrawerToggle}
                to={{ pathname: routes.PROMO_CODES_LIST }}
              >
                <StyledText
                  type={
                    location.pathname.toUpperCase().includes("PROMO_CODES") ? "BrandColor" : null
                  }
                  isSmallScreen={isSmallScreen}
                >
                  Promo Codes
                </StyledText>
              </Link>
            </Tooltip>
          </Grid>
        )}

        {(getUserRole().some((role) => role === "merchant-owner") ||
          getUserPermission().some(
            (permission) =>
              permission === "ms-operation-read" || permission === "ms-operation-write",
          )) && (
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <Tooltip title="To manage membership plans and memberships" arrow placement="right">
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      color: theme.palette.colors.text.primary,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <StyledText
                  type={
                    location.pathname.toUpperCase().includes("MEMBERSHIP") ? "BrandColor" : null
                  }
                  isSmallScreen={isSmallScreen}
                >
                  Membership
                </StyledText>
              </AccordionSummary>
            </Tooltip>

            <AccordionDetailsWithoutPadding>
              <Accordion
                disableGutters
                elevation={0}
                sx={{
                  "&:before": {
                    display: "none",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.colors.text.primary }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <StyledText
                      type={
                        location.pathname.toUpperCase().includes("MEMBERSHIP/PLAN")
                          ? "BrandColor"
                          : null
                      }
                      isSmallScreen={isSmallScreen}
                    >
                      Plan
                    </StyledText>
                  </Box>
                </AccordionSummary>
                <AccordionDetailsWithoutPadding>
                  {(getUserRole().some((role) => role === "merchant-owner") ||
                    getUserPermission().some(
                      (permission) => permission === "ms-operation-write",
                    )) && (
                    <ListItemButton onClick={() => history.push("/membership/plan/create")}>
                      <StyledText
                        type={
                          location.pathname.toUpperCase().includes("MEMBERSHIP/PLAN/CREATE")
                            ? "BrandColor"
                            : null
                        }
                        isSmallScreen={isSmallScreen}
                      >
                        New Plan
                      </StyledText>
                    </ListItemButton>
                  )}
                  {(getUserRole().some((role) => role === "merchant-owner") ||
                    getUserPermission().some(
                      (permission) =>
                        permission === "ms-operation-read" || permission === "ms-operation-write",
                    )) && (
                    <ListItemButton onClick={() => history.push("/membership/plan/list")}>
                      <StyledText
                        type={
                          location.pathname.toUpperCase().includes("MEMBERSHIP/PLAN/LIST")
                            ? "BrandColor"
                            : null
                        }
                        isSmallScreen={isSmallScreen}
                      >
                        Plan List
                      </StyledText>
                    </ListItemButton>
                  )}
                </AccordionDetailsWithoutPadding>
              </Accordion>
            </AccordionDetailsWithoutPadding>
            {(getUserRole().some((role) => role === "merchant-owner") ||
              getUserPermission().some((permission) => permission === "ms-operation-read")) && (
              <AccordionDetailsWithoutPadding>
                <ListItemButton onClick={() => history.push("/membership/list")}>
                  <StyledText
                    type={
                      location.pathname.toUpperCase().includes("MEMBERSHIP/LIST")
                        ? "BrandColor"
                        : null
                    }
                    isSmallScreen={isSmallScreen}
                  >
                    Memberships
                  </StyledText>
                </ListItemButton>
              </AccordionDetailsWithoutPadding>
            )}
          </Accordion>
        )}

        {(getUserRole().some((role) => role === "merchant-owner") ||
          getUserPermission().some(
            (permission) =>
              permission === "ms-operation-read" || permission === "ms-operation-write",
          )) && (
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <Tooltip
              title="To manage your business locations, including gyms, spa parlors, and more"
              arrow
              placement="right"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.colors.text.primary }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <StyledText
                  type={location.pathname.toUpperCase().includes("BUSINESS") ? "BrandColor" : null}
                  isSmallScreen={isSmallScreen}
                >
                  Business
                </StyledText>
              </AccordionSummary>
            </Tooltip>

            <AccordionDetailsWithoutPadding>
              {(getUserRole().some((role) => role === "merchant-owner") ||
                getUserPermission().some((permission) => permission === "ms-operation-write")) && (
                <ListItemButton onClick={() => history.push("/business/create")}>
                  <StyledText
                    type={
                      location.pathname.toUpperCase().includes("BUSINESS/CREATE")
                        ? "BrandColor"
                        : null
                    }
                    isSmallScreen={isSmallScreen}
                  >
                    New Business
                  </StyledText>
                </ListItemButton>
              )}
              {(getUserRole().some((role) => role === "merchant-owner") ||
                getUserPermission().some((permission) => permission === "ms-operation-read")) && (
                <ListItemButton onClick={() => history.push("/business/list")}>
                  <StyledText
                    type={
                      location.pathname.toUpperCase().includes("BUSINESS/LIST")
                        ? "BrandColor"
                        : null
                    }
                    isSmallScreen={isSmallScreen}
                  >
                    Business List
                  </StyledText>
                </ListItemButton>
              )}
            </AccordionDetailsWithoutPadding>
          </Accordion>
        )}

        {(getUserRole().some((role) => role === "merchant-owner") ||
          getUserPermission().some(
            (permission) => permission === "ms-staff-read" || permission === "ms-staff-write",
          )) && (
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <Tooltip title="Staff management" arrow placement="right">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.colors.text.primary }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <StyledText
                  type={location.pathname.toUpperCase().includes("STAFF") ? "BrandColor" : null}
                  isSmallScreen={isSmallScreen}
                >
                  Staff
                </StyledText>
              </AccordionSummary>
            </Tooltip>

            <AccordionDetailsWithoutPadding>
              <ListItemButton onClick={() => history.push("/staff/list")}>
                <StyledText
                  type={
                    location.pathname.toUpperCase().includes("STAFF/LIST") ? "BrandColor" : null
                  }
                  isSmallScreen={isSmallScreen}
                >
                  Staff List
                </StyledText>
              </ListItemButton>
              <ListItemButton onClick={() => history.push("/staff/access")}>
                <StyledText
                  type={
                    location.pathname.toUpperCase().includes("STAFF/ACCESS") ? "BrandColor" : null
                  }
                  isSmallScreen={isSmallScreen}
                >
                  Business Access
                </StyledText>
              </ListItemButton>
            </AccordionDetailsWithoutPadding>
          </Accordion>
        )}

        {(getUserRole().some((role) => role === "merchant-owner") ||
          getUserPermission().some(
            (permission) =>
              permission === "ms-operation-read" ||
              permission === "ms-operation-write" ||
              permission === "ms-trainer-read" ||
              permission === "ms-trainer-write",
          )) && (
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <Tooltip title="User management" arrow placement="right">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.colors.text.primary }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <StyledText
                  type={location.pathname.toUpperCase().includes("USER") ? "BrandColor" : null}
                  isSmallScreen={isSmallScreen}
                >
                  User
                </StyledText>
              </AccordionSummary>
            </Tooltip>

            <AccordionDetailsWithoutPadding>
              <ListItemButton onClick={() => history.push("/user/list")}>
                <StyledText
                  type={location.pathname.toUpperCase().includes("USER/LIST") ? "BrandColor" : null}
                  isSmallScreen={isSmallScreen}
                >
                  User List
                </StyledText>
              </ListItemButton>
              <ListItemButton onClick={() => history.push("/user/access")}>
                <StyledText
                  type={
                    location.pathname.toUpperCase().includes("USER/ACCESS") ? "BrandColor" : null
                  }
                  isSmallScreen={isSmallScreen}
                >
                  Business Access
                </StyledText>
              </ListItemButton>
            </AccordionDetailsWithoutPadding>
          </Accordion>
        )}

        {(getUserRole().some((role) => role === "merchant-owner") ||
          getUserPermission().some(
            (permission) =>
              permission === "ms-operation-read" || permission === "ms-operation-write",
          )) && (
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <Tooltip title="To manage subscription plans and subscribers" arrow placement="right">
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      color: theme.palette.colors.text.primary,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <StyledText
                  type={
                    location.pathname.toUpperCase().includes("SUBSCRIPTION") ? "BrandColor" : null
                  }
                  isSmallScreen={isSmallScreen}
                >
                  Subscription
                </StyledText>
              </AccordionSummary>
            </Tooltip>

            <AccordionDetailsWithoutPadding>
              <Accordion
                disableGutters
                elevation={0}
                sx={{
                  "&:before": {
                    display: "none",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.colors.text.primary }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <StyledText
                      type={
                        location.pathname.toUpperCase().includes("SUBSCRIPTION/PLAN")
                          ? "BrandColor"
                          : null
                      }
                      isSmallScreen={isSmallScreen}
                    >
                      Plan
                    </StyledText>
                  </Box>
                </AccordionSummary>
                <AccordionDetailsWithoutPadding>
                  {(getUserRole().some((role) => role === "merchant-owner") ||
                    getUserPermission().some(
                      (permission) => permission === "ms-operation-write",
                    )) && (
                    <ListItemButton onClick={() => history.push("/subscription/plan/create")}>
                      <StyledText
                        type={
                          location.pathname.toUpperCase().includes("SUBSCRIPTION/PLAN/CREATE")
                            ? "BrandColor"
                            : null
                        }
                        isSmallScreen={isSmallScreen}
                      >
                        New Plan
                      </StyledText>
                    </ListItemButton>
                  )}
                  {(getUserRole().some((role) => role === "merchant-owner") ||
                    getUserPermission().some(
                      (permission) =>
                        permission === "ms-operation-read" || permission === "ms-operation-write",
                    )) && (
                    <ListItemButton onClick={() => history.push("/subscription/plan/list")}>
                      <StyledText
                        type={
                          location.pathname.toUpperCase().includes("SUBSCRIPTION/PLAN/LIST")
                            ? "BrandColor"
                            : null
                        }
                        isSmallScreen={isSmallScreen}
                      >
                        Plan List
                      </StyledText>
                    </ListItemButton>
                  )}
                </AccordionDetailsWithoutPadding>
              </Accordion>
            </AccordionDetailsWithoutPadding>
            {(getUserRole().some((role) => role === "merchant-owner") ||
              getUserPermission().some((permission) => permission === "ms-operation-read")) && (
              <AccordionDetailsWithoutPadding>
                <ListItemButton onClick={() => history.push("/subscription/subscriber/list")}>
                  <StyledText
                    type={
                      location.pathname.toUpperCase().includes("SUBSCRIPTION/SUBSCRIBER/LIST")
                        ? "BrandColor"
                        : null
                    }
                    isSmallScreen={isSmallScreen}
                  >
                    Subscribers
                  </StyledText>
                </ListItemButton>
              </AccordionDetailsWithoutPadding>
            )}
          </Accordion>
        )}

        {(getUserRole().some((role) => role === "merchant-owner") ||
          getUserPermission().some(
            (permission) =>
              permission === "ms-operation-read" || permission === "ms-operation-write",
          )) && (
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <Tooltip
              title="Tools that facilitate the sale of subscriptions and fitness classes"
              arrow
              placement="right"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.colors.text.primary }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <StyledText
                  type={location.pathname.toUpperCase().includes("PACKAGE") ? "BrandColor" : null}
                  isSmallScreen={isSmallScreen}
                >
                  Package
                </StyledText>
              </AccordionSummary>
            </Tooltip>

            <AccordionDetailsWithoutPadding>
              <Accordion
                disableGutters
                elevation={0}
                sx={{
                  "&:before": {
                    display: "none",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.colors.text.primary }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <StyledText
                      type={
                        location.pathname.toUpperCase().includes("PACKAGE/PLAN")
                          ? "BrandColor"
                          : null
                      }
                      isSmallScreen={isSmallScreen}
                    >
                      Plan
                    </StyledText>
                  </Box>
                </AccordionSummary>
                <AccordionDetailsWithoutPadding>
                  {(getUserRole().some((role) => role === "merchant-owner") ||
                    getUserPermission().some(
                      (permission) => permission === "ms-operation-write",
                    )) && (
                    <ListItemButton onClick={() => history.push("/package/plan/create")}>
                      <StyledText
                        type={
                          location.pathname.toUpperCase().includes("PACKAGE/PLAN/CREATE")
                            ? "BrandColor"
                            : null
                        }
                        isSmallScreen={isSmallScreen}
                      >
                        New Plan
                      </StyledText>
                    </ListItemButton>
                  )}
                  {(getUserRole().some((role) => role === "merchant-owner") ||
                    getUserPermission().some(
                      (permission) =>
                        permission === "ms-operation-read" || permission === "ms-operation-write",
                    )) && (
                    <ListItemButton onClick={() => history.push("/package/plan/list")}>
                      <StyledText
                        type={
                          location.pathname.toUpperCase().includes("PACKAGE/PLAN/LIST")
                            ? "BrandColor"
                            : null
                        }
                        isSmallScreen={isSmallScreen}
                      >
                        Plan List
                      </StyledText>
                    </ListItemButton>
                  )}
                </AccordionDetailsWithoutPadding>
              </Accordion>
            </AccordionDetailsWithoutPadding>
            {(getUserRole().some((role) => role === "merchant-owner") ||
              getUserPermission().some((permission) => permission === "ms-operation-read")) && (
              <AccordionDetailsWithoutPadding>
                <ListItemButton onClick={() => history.push("/package/list")}>
                  <StyledText
                    type={
                      location.pathname.toUpperCase().includes("PACKAGE/LIST") ? "BrandColor" : null
                    }
                    isSmallScreen={isSmallScreen}
                  >
                    Purchases
                  </StyledText>
                </ListItemButton>
              </AccordionDetailsWithoutPadding>
            )}

            {(getUserRole().some((role) => role === "merchant-owner") ||
              getUserPermission().some((permission) => permission === "ms-operation-read")) && (
              <AccordionDetailsWithoutPadding>
                <ListItemButton onClick={() => history.push("/appointment/list")}>
                  <StyledText
                    type={
                      location.pathname.toUpperCase().includes("APPOINTMENT/LIST")
                        ? "BrandColor"
                        : null
                    }
                    isSmallScreen={isSmallScreen}
                  >
                    Appointment
                  </StyledText>
                </ListItemButton>
              </AccordionDetailsWithoutPadding>
            )}
          </Accordion>
        )}

        {(getUserRole().some((role) => role === "merchant-owner") ||
          getUserPermission().some(
            (permission) =>
              permission === "ms-operation-read" ||
              permission === "ms-operation-write" ||
              permission === "ms-trainer-read" ||
              permission === "ms-trainer-write",
          )) && (
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <Tooltip
              title="To manage fitness class sessions between users and your staff (trainers)"
              arrow
              placement="right"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.colors.text.primary }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <StyledText
                  type={
                    location.pathname.toUpperCase().includes("FITNESS_CLASS") ? "BrandColor" : null
                  }
                  isSmallScreen={isSmallScreen}
                >
                  Classes
                </StyledText>
              </AccordionSummary>
            </Tooltip>

            <AccordionDetailsWithoutPadding>
              {(getUserRole().some((role) => role === "merchant-owner") ||
                getUserPermission().some(
                  (permission) =>
                    permission === "ms-operation-read" ||
                    permission === "ms-trainer-read" ||
                    permission === "ms-trainer-write",
                )) && (
                <ListItemButton onClick={() => history.push(routes.FITNESS_CLASS_BOOKING)}>
                  <StyledText
                    type={
                      location.pathname.toUpperCase().includes("FITNESS_CLASS/BOOKING")
                        ? "BrandColor"
                        : null
                    }
                    isSmallScreen={isSmallScreen}
                  >
                    Bookings
                  </StyledText>
                </ListItemButton>
              )}
              {(getUserRole().some((role) => role === "merchant-owner") ||
                getUserPermission().some(
                  (permission) =>
                    permission === "ms-operation-write" ||
                    permission === "ms-trainer-read" ||
                    permission === "ms-trainer-write",
                )) && (
                <ListItemButton onClick={() => history.push("/fitness_class/create")}>
                  <StyledText
                    type={
                      location.pathname.toUpperCase().includes("FITNESS_CLASS/CREATE")
                        ? "BrandColor"
                        : null
                    }
                    isSmallScreen={isSmallScreen}
                  >
                    New Class
                  </StyledText>
                </ListItemButton>
              )}
              {(getUserRole().some((role) => role === "merchant-owner") ||
                getUserPermission().some(
                  (permission) =>
                    permission === "ms-operation-read" ||
                    permission === "ms-operation-write" ||
                    permission === "ms-trainer-read" ||
                    permission === "ms-trainer-write",
                )) && (
                <ListItemButton onClick={() => history.push("/fitness_class/list")}>
                  <StyledText
                    type={
                      location.pathname.toUpperCase().includes("FITNESS_CLASS/LIST")
                        ? "BrandColor"
                        : null
                    }
                    isSmallScreen={isSmallScreen}
                  >
                    Class List
                  </StyledText>
                </ListItemButton>
              )}
              {(getUserRole().some((role) => role === "merchant-owner") ||
                getUserPermission().some(
                  (permission) =>
                    permission === "ms-operation-read" ||
                    permission === "ms-operation-write" ||
                    permission === "ms-trainer-read" ||
                    permission === "ms-trainer-write",
                )) && (
                <ListItemButton onClick={() => history.push("/fitness_class/sessions")}>
                  <StyledText
                    type={
                      location.pathname.toUpperCase().includes("FITNESS_CLASS/SESSIONS")
                        ? "BrandColor"
                        : null
                    }
                    isSmallScreen={isSmallScreen}
                  >
                    Sessions
                  </StyledText>
                </ListItemButton>
              )}
            </AccordionDetailsWithoutPadding>
          </Accordion>
        )}

        {(getUserRole().some((role) => role === "merchant-owner") ||
          getUserPermission().some(
            (permission) =>
              permission === "ms-operation-read" || permission === "ms-operation-write",
          )) && (
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <Tooltip
              title="To manage events sessions between users and your staff (trainers)"
              arrow
              placement="right"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.colors.text.primary }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <StyledText
                  type={location.pathname.toUpperCase().includes("EVENT") ? "BrandColor" : null}
                  isSmallScreen={isSmallScreen}
                >
                  Event
                </StyledText>
              </AccordionSummary>
            </Tooltip>

            <AccordionDetailsWithoutPadding>
              {(getUserRole().some((role) => role === "merchant-owner") ||
                getUserPermission().some((permission) => permission === "ms-operation-read")) && (
                <ListItemButton onClick={() => history.push(routes.EVENT_BOOKING)}>
                  <StyledText
                    type={
                      location.pathname.toUpperCase().includes("EVENT/BOOKING")
                        ? "BrandColor"
                        : null
                    }
                    isSmallScreen={isSmallScreen}
                  >
                    Bookings
                  </StyledText>
                </ListItemButton>
              )}
              {(getUserRole().some((role) => role === "merchant-owner") ||
                getUserPermission().some((permission) => permission === "ms-operation-write")) && (
                <ListItemButton onClick={() => history.push(routes.EVENT_CREATE)}>
                  <StyledText
                    type={
                      location.pathname.toUpperCase().includes("EVENT/CREATE") ? "BrandColor" : null
                    }
                    isSmallScreen={isSmallScreen}
                  >
                    New Event
                  </StyledText>
                </ListItemButton>
              )}
              {(getUserRole().some((role) => role === "merchant-owner") ||
                getUserPermission().some(
                  (permission) =>
                    permission === "ms-operation-read" || permission === "ms-operation-write",
                )) && (
                <ListItemButton onClick={() => history.push(routes.EVENT_LIST)}>
                  <StyledText
                    type={
                      location.pathname.toUpperCase().includes("EVENT/LIST") ? "BrandColor" : null
                    }
                    isSmallScreen={isSmallScreen}
                  >
                    Event List
                  </StyledText>
                </ListItemButton>
              )}
              {(getUserRole().some((role) => role === "merchant-owner") ||
                getUserPermission().some(
                  (permission) =>
                    permission === "ms-operation-read" || permission === "ms-operation-write",
                )) && (
                <ListItemButton onClick={() => history.push(routes.EVENT_RESPONSES)}>
                  <StyledText
                    type={
                      location.pathname.toUpperCase().includes("EVENT/RESPONSES")
                        ? "BrandColor"
                        : null
                    }
                    isSmallScreen={isSmallScreen}
                  >
                    Responses
                  </StyledText>
                </ListItemButton>
              )}
              {(getUserRole().some((role) => role === "merchant-owner") ||
                getUserPermission().some(
                  (permission) =>
                    permission === "ms-operation-read" || permission === "ms-operation-write",
                )) && (
                <ListItemButton onClick={() => history.push(routes.EVENT_SESSIONS)}>
                  <StyledText
                    type={
                      location.pathname.toUpperCase().includes("EVENT/SESSIONS")
                        ? "BrandColor"
                        : null
                    }
                    isSmallScreen={isSmallScreen}
                  >
                    Sessions
                  </StyledText>
                </ListItemButton>
              )}
            </AccordionDetailsWithoutPadding>
          </Accordion>
        )}

        {(getUserRole().some((role) => role === "merchant-owner") ||
          getUserPermission().some(
            (permission) => permission === "ms-finance-read" || permission === "ms-finance-write",
          )) && (
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <Tooltip title="Summary of earnings" arrow placement="right">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.colors.text.primary }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <StyledText
                  type={location.pathname.toUpperCase().includes("STATEMENT") ? "BrandColor" : null}
                  isSmallScreen={isSmallScreen}
                >
                  Statement
                </StyledText>
              </AccordionSummary>
            </Tooltip>

            <AccordionDetailsWithoutPadding>
              <ListItemButton onClick={() => history.push("/statement/current")}>
                <StyledText
                  type={
                    location.pathname.toUpperCase().includes("STATEMENT/CURRENT")
                      ? "BrandColor"
                      : null
                  }
                  isSmallScreen={isSmallScreen}
                >
                  Current Statement
                </StyledText>
              </ListItemButton>
              <ListItemButton onClick={() => history.push("/statement/closed")}>
                <StyledText
                  type={
                    location.pathname.toUpperCase().includes("STATEMENT/CLOSED")
                      ? "BrandColor"
                      : null
                  }
                  isSmallScreen={isSmallScreen}
                >
                  Closed Statement
                </StyledText>
              </ListItemButton>
            </AccordionDetailsWithoutPadding>
          </Accordion>
        )}

        {(getUserRole().some((role) => role === "merchant-owner") ||
          getUserPermission().some((permission) => permission === "ms-operation-read")) && (
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <Tooltip
              title="Hourly bookings made by the users to your businesses"
              arrow
              placement="right"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.colors.text.primary }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <StyledText
                  type={
                    location.pathname.toUpperCase().includes("HOURLY_BOOKING") ? "BrandColor" : null
                  }
                  isSmallScreen={isSmallScreen}
                >
                  Hourly Booking
                </StyledText>
              </AccordionSummary>
            </Tooltip>

            <AccordionDetailsWithoutPadding>
              <ListItemButton onClick={() => history.push("/hourly_booking/list")}>
                <StyledText
                  type={
                    location.pathname.toUpperCase().includes("HOURLY_BOOKING/LIST")
                      ? "BrandColor"
                      : null
                  }
                  isSmallScreen={isSmallScreen}
                >
                  Booking List
                </StyledText>
              </ListItemButton>
            </AccordionDetailsWithoutPadding>
          </Accordion>
        )}

        {(getUserRole().some((role) => role === "merchant-owner") ||
          getUserPermission().some(
            (permission) =>
              permission === "ms-operation-read" ||
              permission === "ms-operation-write" ||
              permission === "ms-trainer-read" ||
              permission === "ms-trainer-write",
          )) && (
          <Grid item xs={12} sx={{ paddingY: "12px" }}>
            <Tooltip title="To manage exclusive members" arrow placement="right">
              <Link
                style={{ textDecoration: "none" }}
                onClick={handleDrawerToggle}
                to={{ pathname: routes.EXCLUSIVE_REFERRAL_LIST }}
              >
                <StyledText
                  type={
                    location.pathname.toUpperCase().includes("EXCLUSIVE_REFERRAL")
                      ? "BrandColor"
                      : null
                  }
                  isSmallScreen={isSmallScreen}
                >
                  Exclusive Referral
                </StyledText>
              </Link>
            </Tooltip>
          </Grid>
        )}

        {(getUserRole().some((role) => role === "merchant-owner") ||
          getUserPermission().some(
            (permission) => permission === "ms-general-read" || permission === "ms-general-write",
          )) && (
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <Tooltip title="System settings" arrow placement="right">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.colors.text.primary }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <StyledText
                  type={
                    location.pathname.toUpperCase().includes("HOURLY_TIMESLOT") ||
                    location.pathname.toUpperCase().includes("PROFILE_DETAIL") ||
                    location.pathname.toUpperCase().includes("BANK_DETAIL")
                      ? "BrandColor"
                      : null
                  }
                  isSmallScreen={isSmallScreen}
                >
                  Setting
                </StyledText>
              </AccordionSummary>
            </Tooltip>

            <AccordionDetailsWithoutPadding>
              <ListItemButton onClick={() => history.push("/profile_detail")}>
                <StyledText
                  type={
                    location.pathname.toUpperCase().includes("PROFILE_DETAIL") ? "BrandColor" : null
                  }
                  isSmallScreen={isSmallScreen}
                >
                  Profile
                </StyledText>
              </ListItemButton>
              {getUserRole().some((role) => role === "merchant-owner") && (
                <ListItemButton onClick={() => history.push("/bank_detail")}>
                  <StyledText
                    type={
                      location.pathname.toUpperCase().includes("BANK_DETAIL") ? "BrandColor" : null
                    }
                    isSmallScreen={isSmallScreen}
                  >
                    Bank Details
                  </StyledText>
                </ListItemButton>
              )}

              <Accordion
                disableGutters
                elevation={0}
                sx={{
                  "&:before": {
                    display: "none",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.colors.text.primary }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <StyledText type={null} isSmallScreen={isSmallScreen}>
                      Fitness
                    </StyledText>
                  </Box>
                </AccordionSummary>
                <AccordionDetailsWithoutPadding>
                  <ListItemButton onClick={() => history.push("/hourly_timeslot/list")}>
                    <StyledText
                      type={
                        location.pathname.toUpperCase().includes("HOURLY_TIMESLOT/LIST")
                          ? "BrandColor"
                          : null
                      }
                      isSmallScreen={isSmallScreen}
                    >
                      Timeslot List
                    </StyledText>
                  </ListItemButton>
                  <ListItemButton onClick={() => history.push("/off-day-calendar")}>
                    <StyledText
                      type={
                        location.pathname.toUpperCase().includes("OFF-DAY-CALENDAR")
                          ? "BrandColor"
                          : null
                      }
                      isSmallScreen={isSmallScreen}
                    >
                      Off Day Calendar
                    </StyledText>
                  </ListItemButton>
                  <ListItemButton onClick={() => history.push("/off-hour/list")}>
                    <StyledText
                      type={
                        location.pathname.toUpperCase().includes("OFF-HOUR/LIST")
                          ? "BrandColor"
                          : null
                      }
                      isSmallScreen={isSmallScreen}
                    >
                      Off Hour List
                    </StyledText>
                  </ListItemButton>
                </AccordionDetailsWithoutPadding>
              </Accordion>

              <Accordion
                disableGutters
                elevation={0}
                sx={{
                  "&:before": {
                    display: "none",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.colors.text.primary }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <StyledText type={null} isSmallScreen={isSmallScreen}>
                      Appointment
                    </StyledText>
                  </Box>
                </AccordionSummary>
                <AccordionDetailsWithoutPadding>
                  <ListItemButton onClick={() => history.push("/schedule/list")}>
                    <StyledText
                      type={
                        location.pathname.toUpperCase().includes("SCHEDULE/LIST")
                          ? "BrandColor"
                          : null
                      }
                      isSmallScreen={isSmallScreen}
                    >
                      Schedules
                    </StyledText>
                  </ListItemButton>
                </AccordionDetailsWithoutPadding>
              </Accordion>
            </AccordionDetailsWithoutPadding>
          </Accordion>
        )}

        {isSmallScreen && (
          <>
            <Grid item xs={12}>
              <SeperateLine />
            </Grid>

            <Grid item xs={12}>
              <Link
                style={{ textDecoration: "none" }}
                onClick={handleDrawerToggle}
                to={{ pathname: routes.MOBILE_ACCOUNT_MENU }}
              >
                <StyledText isSmallScreen={isSmallScreen}>Account</StyledText>
              </Link>
            </Grid>
          </>
        )}
      </Grid>
      {isSmallScreen && (
        <FlexEndBox>
          <Box sx={{ width: "100%" }}>
            <CtaBorderButton onClickButton={handleLogout} width="100%">
              <Text type="BrandColor">Logout</Text>
            </CtaBorderButton>
          </Box>
        </FlexEndBox>
      )}
    </ColumnFlexBox>
  );
}

DrawerItems.defaultProps = {
  openDrawer: false,
  setOpenDrawer: null,
};

DrawerItems.propTypes = {
  openDrawer: PropTypes.bool,
  setOpenDrawer: PropTypes.func,
};

export default DrawerItems;
