import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getReq, postReq, putReq } from "../../api-services";

export const getFitnessBookingList = createAsyncThunk(
  "merchants/fitness_classes/booking/index",
  async (payload) => {
    const {
      statuses,
      businessIds,
      categoryIds,
      classIds,
      sessionIds,
      userIds,
      startAt,
      endAt,
      page,
    } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/fitness_classes/bookings?${
        page ? "page=".concat(page) : "page=1"
      }${businessIds ? "&businessIds=".concat(businessIds) : ""}${
        categoryIds ? "&categoryIds=".concat(categoryIds) : ""
      }${classIds ? "&classIds=".concat(classIds) : ""}${
        sessionIds ? "&sessionIds=".concat(sessionIds) : ""
      }${userIds ? "&userIds=".concat(userIds) : ""}${startAt ? "&startAt=".concat(startAt) : ""}${
        endAt ? "&endAt=".concat(endAt) : ""
      }${statuses ? "&statuses=".concat(statuses) : ""}`,
    );
    return response;
  },
);

export const createFitnessBooking = createAsyncThunk(
  "merchants/fitness_classes/booking/add",
  async (payload) => {
    const {
      classId,
      userPhoneNumbers,
      startAt,
      endAt,
      staffIds,
      amountCents,
      membershipAmountCents,
    } = payload;
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/fitness_classes/bookings`,
      {
        classId,
        userPhoneNumbers,
        startAt,
        endAt,
        staffIds,
        amountCents,
        membershipAmountCents,
      },
    );
    return response;
  },
);

export const cancelFitnessBooking = createAsyncThunk(
  "merchants/fitness_classes/booking/cancel",
  async (payload) => {
    const { bookingIds } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/fitness_classes/bookings/cancel`,
      { bookingIds },
    );
    return response;
  },
);

export const createFitnessBookingPackage = createAsyncThunk(
  "merchants/fitness_classes/booking/add/package",
  async (payload) => {
    const { classId, userPhoneNumber, startAt, endAt, staffIds, packageId } = payload;
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/fitness_classes/bookings/package`,
      {
        classId,
        userPhoneNumber,
        startAt,
        endAt,
        staffIds,
        packageId,
      },
    );
    return response;
  },
);

export const checkUserBookingsPackageAvailabilities = createAsyncThunk(
  "merchants/fitness_classes/booking/user_package_availabilities",
  async (payload) => {
    const { classId, userPhoneNumber } = payload;
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/merchants/fitness_classes/bookings/user_package_availabilities`,
      {
        classId,
        userPhoneNumber,
      },
    );
    return response;
  },
);

const fitnessBookingSlice = createSlice({
  name: "fitnessBooking",
  initialState: {
    getFitnessBookingListObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createFitnessBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    cancelFitnessBookingObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    checkUserBookingsPackageAvailabilitiesObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
    createFitnessBookingPackageObj: {
      status: "idle",
      data: null,
      successMessage: null,
      errorMessage: null,
    },
  },
  reducers: {},
  extraReducers: {
    [getFitnessBookingList.pending]: (state) => {
      state.getFitnessBookingListObj.status = "pending";
    },
    [getFitnessBookingList.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.getFitnessBookingListObj.status = "succeeded";
      state.getFitnessBookingListObj.data = data;
      state.getFitnessBookingListObj.successMessage = message;
    },
    [getFitnessBookingList.rejected]: (state, action) => {
      const { message } = action.error;

      state.getFitnessBookingListObj.status = "failed";
      state.getFitnessBookingListObj.errorMessage = message;
    },
    [createFitnessBooking.pending]: (state) => {
      state.createFitnessBookingObj.status = "pending";
    },
    [createFitnessBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createFitnessBookingObj.status = "succeeded";
      state.createFitnessBookingObj.data = data;
      state.createFitnessBookingObj.successMessage = message;
    },
    [createFitnessBooking.rejected]: (state, action) => {
      const { message } = action.error;

      state.createFitnessBookingObj.status = "failed";
      state.createFitnessBookingObj.errorMessage = message;
    },
    [cancelFitnessBooking.pending]: (state) => {
      state.cancelFitnessBookingObj.status = "pending";
    },
    [cancelFitnessBooking.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.cancelFitnessBookingObj.status = "succeeded";
      state.cancelFitnessBookingObj.data = data;
      state.cancelFitnessBookingObj.successMessage = message;
    },
    [cancelFitnessBooking.rejected]: (state, action) => {
      const { message } = action.error;

      state.cancelFitnessBookingObj.status = "failed";
      state.cancelFitnessBookingObj.errorMessage = message;
    },
    [createFitnessBookingPackage.pending]: (state) => {
      state.createFitnessBookingPackageObj.status = "pending";
    },
    [createFitnessBookingPackage.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.createFitnessBookingPackageObj.status = "succeeded";
      state.createFitnessBookingPackageObj.data = data;
      state.createFitnessBookingPackageObj.successMessage = message;
    },
    [createFitnessBookingPackage.rejected]: (state, action) => {
      const { message } = action.error;

      state.createFitnessBookingPackageObj.status = "failed";
      state.createFitnessBookingPackageObj.errorMessage = message;
    },
    [checkUserBookingsPackageAvailabilities.pending]: (state) => {
      state.checkUserBookingsPackageAvailabilitiesObj.status = "pending";
    },
    [checkUserBookingsPackageAvailabilities.fulfilled]: (state, action) => {
      const { data, message } = action.payload;

      state.checkUserBookingsPackageAvailabilitiesObj.status = "succeeded";
      state.checkUserBookingsPackageAvailabilitiesObj.data = data;
      state.checkUserBookingsPackageAvailabilitiesObj.successMessage = message;
    },
    [checkUserBookingsPackageAvailabilities.rejected]: (state, action) => {
      const { message } = action.error;

      state.checkUserBookingsPackageAvailabilitiesObj.status = "failed";
      state.checkUserBookingsPackageAvailabilitiesObj.errorMessage = message;
    },
  },
});

export default fitnessBookingSlice.reducer;

// state
export const fitnessBookingSelector = (state) => state.fitnessBooking;
