import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Grid, IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import dayjs from "dayjs";
import WhiteBgCardContainer from "../../../components/container/white-bg-card-container.component";
import FormCheckBox from "../../../components/forms/form-check-box.component";
import FormFieldText from "../../../components/forms/form-field-text.component";
import FormPrice from "../../../components/forms/form-price.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import Text from "../../../components/text.component";
import routes from "../../../navigation/routes";
import {
  getTakenHourlyTimeslotList,
  hourlyTimeslotSelector,
  showHourlyTimeslot,
  updateHourlyTimeslot,
} from "../../../services/hourly-timeslot/hourly-timeslot.slice.service";
import FormTimePicker from "../components/form-hour-picker.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

export default function HourlyTimeslotEditScreen() {
  const formRef = useRef();
  const theme = useTheme();
  const location = useLocation();
  const timeslotId = new URLSearchParams(location.search).get("timeslotId");
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  const history = useHistory();
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const [isLoading, setIsLoading] = useState(false);
  const { showHourlyTimeslotObj } = useSelector(hourlyTimeslotSelector);
  const { getTakenHourlyTimeslotListObj } = useSelector(hourlyTimeslotSelector);
  const [allowEditTimeslot, setAllowEditTimeslot] = useState(false);

  const validationSchema = Yup.object().shape({
    startTime: Yup.string().nullable().required().label("Start time"),
    endTime: Yup.string().nullable().required().label("End time"),
    priceRM: Yup.number().required().label("Price"),
    membershipPriceRM: Yup.number()
      .lessThan(Yup.ref("priceRM"))
      .nullable()
      .label("Membership Price"),
    exclusivePriceRM: Yup.number().required().label("Exclusive Price"),
    unlimited: Yup.boolean().required(),
    capacity: Yup.number().required().label("Capacity"),
  });

  const onGetTakenHourlyTimeslotList = () => {
    dispatch(getTakenHourlyTimeslotList()).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    onGetTakenHourlyTimeslotList();
    setIsLoading(true);
    dispatch(showHourlyTimeslot({ timeslotId })).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
        });
      }
    });
  }, []);

  const getHourNum = (val) => val?.split(":")[0] || "";

  const disabledHours = (records) => records?.map((r) => Number(getHourNum(r)));

  const renderStartTimePicker = () => {
    if (
      showHourlyTimeslotObj?.data?.startTime &&
      getTakenHourlyTimeslotListObj.status === "succeeded" &&
      getTakenHourlyTimeslotListObj.data
    ) {
      const takenHours = [...getTakenHourlyTimeslotListObj?.data].filter(
        (t) => t !== showHourlyTimeslotObj.data.startTime,
      );
      return (
        <FormTimePicker
          name="startTime"
          setEndTimeAnHourLater={true}
          disabledHours={disabledHours(takenHours) || []}
          isDisabled={!allowEditTimeslot}
        />
      );
    }
    if (getTakenHourlyTimeslotListObj.status === "failed") {
      return (
        <FormTimePicker
          name="startTime"
          setEndTimeAnHourLater={true}
          disabledHours={[]}
          isDisabled={true}
        />
      );
    }
    return <></>;
  };

  const onUpdateHourlyTimeslot = (values) => {
    const amountCents = values.priceRM * 100;
    const membershipAmountCents = values.membershipPriceRM ? values.membershipPriceRM * 100 : null;
    const exclusiveReferralAmountCents = values.exclusivePriceRM * 100;
    const startTime = `${dayjs(values.startTime).format("HH:00:00")}`;
    const endTime = `${dayjs(values.endTime).format("HH:00:00")}`;

    if (startTime >= endTime) {
      formRef.current.setFieldError("endTime", "End time must be later than start time");
      return;
    }

    setIsLoading(true);
    const checkError = () => {
      if (values.unlimited && Number(values.capacity) > 0)
        return "Capacity is invalid, please either decrease capacity count to 0 or set unlimited to false.";
      if (amountCents < 1 && Number(values.capacity) > 0)
        return "Price is invalid, please either increase price minimum to RM1 or decrease capacity count to 0.";
      if (values.unlimited && Number(values.capacity) === 0 && amountCents === 0)
        return "Please set unlimited to false to set timeslot to unavailable.";
      return "The given data was invalid.";
    };

    const payloadParams = {
      ...values,
      capacity: values.unlimited ? 0 : values.capacity,
      timeslotId,
      amountCents,
      membershipAmountCents,
      exclusiveReferralAmountCents,
      startTime,
      endTime,
    };

    dispatch(updateHourlyTimeslot(payloadParams)).then(({ meta, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        if (payload.message) {
          createSnackBar({
            message: payload.message,
            type: "success",
          });
        }
        setAllowEditTimeslot(false);
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: checkError(),
          type: "error",
        });
      }
      setIsLoading(false);
    });
  };

  return (
    <Grid>
      <BackdropLoading isLoading={isLoading} />
      {showHourlyTimeslotObj.status === "succeeded" && (
        <>
          <Formik
            innerRef={formRef}
            validationSchema={validationSchema}
            onSubmit={onUpdateHourlyTimeslot}
            initialValues={{
              capacity: showHourlyTimeslotObj.data.capacity,
              unlimited: showHourlyTimeslotObj.data.unlimited,
              priceRM: showHourlyTimeslotObj.data.price,
              membershipPriceRM: showHourlyTimeslotObj.data.membershipPrice,
              exclusivePriceRM: showHourlyTimeslotObj.data.exclusivePrice,
              startTime: dayjs(showHourlyTimeslotObj.data.startTime, "HH:mm:ss"),
              endTime: dayjs(showHourlyTimeslotObj.data.endTime, "HH:mm:ss"),
            }}
          >
            {({ values, handleSubmit, dirty }) => (
              <Form>
                <Grid
                  container
                  spacing={5}
                  sx={{
                    paddingX: isMobile
                      ? theme.dimensions.MobilePadding
                      : theme.dimensions.PCPadding,
                    paddingTop: theme.dimensions.ScreenPaddingY,
                  }}
                >
                  <Grid item xs={12}>
                    <SpaceBetweenBox>
                      <Text variant="screenLabel">Edit Hourly Timeslot</Text>
                      <Button
                        onClick={() => history.push(routes.HOURLY_TIMESLOT_LIST)}
                        sx={{
                          textTransform: "none",
                          padding: "0px",
                          ":hover": { backgroundColor: "transparent" },
                        }}
                      >
                        <Text>Back</Text>
                      </Button>
                    </SpaceBetweenBox>
                  </Grid>

                  <Grid item xs={12}>
                    <WhiteBgCardContainer>
                      <Grid container spacing={isMobile ? 1 : 3}>
                        <Grid item xs={4} sm={3}>
                          <Text variant="screenLabel">Hourly Timeslot</Text>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          {allowEditTimeslot ? (
                            <>
                              <IconButton size="small" onClick={handleSubmit} disabled={!dirty}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                              <IconButton size="small" onClick={() => setAllowEditTimeslot(false)}>
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setAllowEditTimeslot(true);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </>
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                Start Time
                              </Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Start Time
                                </Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>{renderStartTimePicker()}</Box>
                            </Box>
                          </FormContainer>
                        </Grid>

                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                End Time
                              </Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  End Time
                                </Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <FormTimePicker name="endTime" isDisabled={!allowEditTimeslot} />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>

                        {!values.unlimited && (
                          <Grid item xs={12}>
                            {isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Capacity
                                </Text>
                              </LabelContainer>
                            )}
                            <FormContainer>
                              {!isMobile && (
                                <LabelContainer>
                                  <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                    Capacity
                                  </Text>
                                </LabelContainer>
                              )}
                              <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                <Box sx={{ width: "100%" }}>
                                  <FormFieldText
                                    name="capacity"
                                    placeholder="0"
                                    label="Capacity"
                                    type="number"
                                    disabled={!allowEditTimeslot}
                                  />
                                </Box>
                              </Box>
                            </FormContainer>
                          </Grid>
                        )}

                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Status</Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Unlimited
                                </Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <FormCheckBox name="unlimited" disabled={!allowEditTimeslot} />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>

                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Price</Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>Price</Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <FormPrice
                                  placeholder="00.00"
                                  name="priceRM"
                                  disabled={!allowEditTimeslot}
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>

                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                Membership Price
                              </Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Membership Price
                                </Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <FormPrice
                                  placeholder="00.00"
                                  name="membershipPriceRM"
                                  disabled={!allowEditTimeslot}
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>

                        <Grid item xs={12}>
                          {isMobile && (
                            <LabelContainer>
                              <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                Exclusive Price
                              </Text>
                            </LabelContainer>
                          )}
                          <FormContainer>
                            {!isMobile && (
                              <LabelContainer>
                                <Text sx={{ fontWeight: theme.fonts.fontWeights.bold }}>
                                  Exclusive Price
                                </Text>
                              </LabelContainer>
                            )}
                            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                              <Box sx={{ width: "100%" }}>
                                <FormPrice
                                  placeholder="00.00"
                                  name="exclusivePriceRM"
                                  disabled={!allowEditTimeslot}
                                />
                              </Box>
                            </Box>
                          </FormContainer>
                        </Grid>
                      </Grid>
                    </WhiteBgCardContainer>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Grid>
  );
}
